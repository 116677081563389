
import './app.scss';
import './layout/styles.scss';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import NoPage from './pages/nopage/NoPage';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { useState } from 'react';

import Auth, { SessionContext, useAuthSession } from './utility/Auth';
import { GlobalContext, GlobalContextProvider, useGlobal } from './utility/CTX';
import { initialState, reducers } from './utility/reduce';
import Waitlist from './pages/waitlist/Waitlist';
import PrivacyPolicy from './pages/privacy/PrivacyPolicy';
import TermsAndConditions from './pages/privacy/TermsAndConditions';








function App() {
  const session = useAuthSession();




  return (
    <GlobalContextProvider initialState={initialState} reducers={reducers}>
      <SessionContext.Provider value={session}>
        <BrowserRouter>

          <Header />

          <Routes>
            <Route path="/"             element={<Home />} />
            <Route path="/about"        element={<About />} />
            <Route path="/contact"      element={<Contact />} />
            <Route path="/nopage"       element={<NoPage />} />
            <Route path="/waitlist"     element={<Waitlist />} />
            <Route path="/privacy"      element={<PrivacyPolicy />} />
            <Route path="/terms"        element={<TermsAndConditions />} />
          </Routes>

          <Footer />

        </BrowserRouter>
      </SessionContext.Provider>
    </GlobalContextProvider>
  );
}

export default App;
